<script lang="ts">
	import 'virtual:windi.css'
	import '../../main.css'
	import { browser } from '$app/env'
	import { page, session } from '$app/stores'
	import supabase from '$lib/db'
	import { afterUpdate } from 'svelte'
	import { appStore, userStore } from '$lib/store'
	import DarkMode from 'svelte-dark-mode'
	import Notify from '$lib/components/notify/index.svelte'

	import { goto } from '$app/navigation'
	import type { definitions } from '$lib/types'

	afterUpdate(() => {
		document.body.className = $appStore.theme
	})

	if (browser) {
		import('virtual:windi-devtools')
		let auth = supabase.auth.session()
		$session = auth
		supabase.auth.onAuthStateChange(async (_event, userSession) => {
			const response = await fetch('/api/authchange/', {
				method: 'post',
				body: JSON.stringify(userSession)
			})

			if (!response.ok) {
				appStore.createToast('Info', `Your session has expired and you have been logged out`)
				//await goto('/')
			}
			await updateUserStore(userSession)
			// if ($page.path.includes('app') || $page.path.includes('admin')) return
			// else if (userSession?.user?.role?.includes('admin')) await goto('/admin')
			// else await goto('/app')
		})
	}
	const updateUserStore = async (userSession: any) => {
		if (userSession.user?.aud === 'authenticated') {
			const { data: currentUser, error } = await supabase
				.from<definitions['users']>('users')
				.update({ is_verified: !!userSession.user.confirmed_at })
				.match({ auth_id: userSession.user.id })
				.single()
			if (error) {
				//TODO: not a toast but a error log
				//appStore.createToast('Error', `And error occured while updating logged in user ${error.message}`)
				return
			}
			if (currentUser) {
				userStore.updateCurrentUser(currentUser)
			} else {
				userStore.resetUser()
			}
		}
	}
	//computed
	$: theme = $appStore.theme
</script>

<DarkMode bind:theme />
<Notify />

<slot />

<style>
</style>
