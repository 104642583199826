<script lang="ts">
	import { appStore } from '$lib/store'
	import Icon from 'svelte-hero-icons/Icon.svelte'
	import { CheckCircle, ExclamationCircle, InformationCircle, XCircle } from 'svelte-hero-icons'
	import { scale } from 'svelte/transition'
	import { cubicIn, cubicOut } from 'svelte/easing'

	//init
	let toggle = false
	let visible = false

	//logic
	const getNotificationClass = (alertTitle: string | undefined) => {
		let notificationClass = ''
		if (!alertTitle) return ''
		switch (alertTitle.toLowerCase()) {
			case 'info':
				notificationClass = 'from-blue-500 to-blue-600'
				break
			case 'success':
				notificationClass = 'from-green-500 to-green-600'
				break
			case 'error':
				notificationClass = 'from-red-500 to-pink-500'
				break
			case 'warning':
				notificationClass = 'from-yellow-400 to-yellow-500'
				break
			default:
				notificationClass = 'from-blue-500 to-blue-600'
		}
		return notificationClass
	}
	//computed
	$: visible = $appStore.notify?.length > 0
</script>

{#if visible}
	<div class="p-4 top-0 right-0 absolute overflow-x-hidden">
		{#each $appStore.notify as appAlert, i}
			<div
				in:scale={{ duration: 200, start: 0.95, easing: cubicIn }}
				out:scale={{ duration: 500, start: 0.95, easing: cubicOut }}
				on:click={() => appStore.destroyToast(i)}
				class={`bg-gray-900 bg-gradient-to-r text-white p-3 rounded mb-3 shadow-lg flex items-center  ${getNotificationClass(
					appAlert.title
				)}`}
			>
				{#if appAlert.title?.toLowerCase() === 'info'}
					<Icon
						src={InformationCircle}
						solid
						class="h-6 mr-2 text-blue-400 w-6"
						aria-hidden="true"
					/>
				{:else if appAlert.title?.toLowerCase() === 'success'}
					<Icon src={CheckCircle} solid class="h-6 mr-2 text-green-400 w-6" aria-hidden="true" />
				{:else if appAlert.title?.toLowerCase() === 'error'}
					<Icon src={XCircle} solid class="h-6 mr-2 text-red-400 w-6" aria-hidden="true" />
				{:else}
					<Icon
						src={ExclamationCircle}
						solid
						class="h-6 mr-2 text-yellow-400 w-6"
						aria-hidden="true"
					/>
				{/if}
				{appAlert.message}
			</div>
		{/each}
	</div>
{/if}
